import React, { useState, useEffect, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import AdminCard from '../components/AdminCard';
import UserContext from '../UserContext';

export default function AdminDashboard() {
    const { user } = useContext(UserContext);
    const [totalActiveAmount, setTotalActiveAmount] = useState(0);
    const [adminData, setAdminData] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [agents, setAgents] = useState([]);
    const [loadingstations, setLoadingStations] = useState([]);
    const [allagents, setAllAgents] = useState([]);
    const [allplayers, setAllPlayers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [filteredAgents, setFilteredAgents] = useState([]);
    const [filteredLoadingStations, setFilteredLoadingStations] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');
    //
    const [setCounts] = useState({ agentCount: 0, playerCount: 0 });

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/subagent/${user.id}/count`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setCounts(data);
                } catch (error) {
                    console.error('Error fetching profiles:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }
    }, [user]);

    useEffect(() => {
        fetchAds();
        fetchAdminData();
        fetchProfiles();
        fetchAgents();
        fetchAllAgents();
        fetchAllPlayers();
        fetchAllLoadingStations();
    }, []);

    // Fetch admin data with earnings attributes
    const fetchAdminData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAdmin`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch admin data');
            }

            const data = await response.json();
            setAdminData(data); // Store the admin data in state
        } catch (error) {
            console.error('Error fetching admin data:', error);
        }
    };

    const fetchAds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/advertisement/adslist`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            calculateTotalActiveAmount(data); // Calculate total amount
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    const calculateTotalActiveAmount = (ads) => {
        const total = ads.filter((ad) => ad.isActive).reduce((sum, ad) => sum + (ad.amount || 0), 0);
        setTotalActiveAmount(total);
    };

    const fetchProfiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAgentPlayers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setProfiles(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        }
    };

    const fetchAgents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllAgents`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAgents(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchAllAgents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/AllAgents`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAllAgents(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchAllPlayers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllPlayers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAllPlayers(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchAllLoadingStations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllLoadingStations`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setLoadingStations(data);
        } catch (error) {
            console.error('Error fetching loading stations:', error);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

    const handleLoaderSearch = () => {
        filterLoaderResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let profileResults = profiles;
        let agentResults = agents;

        if (searchTerm.trim() !== '') {
            profileResults = profiles.filter((profile) =>
                Object.values(profile).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            agentResults = agents.filter((agent) =>
                Object.values(agent).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (profileResults.length === 0 && agentResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredProfiles(profileResults);
        setFilteredAgents(agentResults);
    };

    const filterLoaderResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        let agentResults = loadingstations; // Initially set to all agents

        if (searchTerm.trim() !== '') {
            agentResults = loadingstations.filter((agent) =>
                Object.values(agent).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (agentResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }
        setFilteredLoadingStations(agentResults);
    };

    return (
        isLoading ? <Loading /> :
            <>
                <h4 className="profile-name p-1 text-primary">Welcome back stakeholder {user.username}</h4>
                <a href="#agents" className='text-black mb-3'>Go to Loading Stations</a>
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Title className='text-primary'>Name: {user.firstName} {user.lastName}</Card.Title>
                        <Card.Text>Email: {user.email}</Card.Text>
                        <Card.Text>All loaders:<strong> {allagents.length}</strong></Card.Text>
                        <Card.Text>All players:<strong> {allplayers.length}</strong></Card.Text>
                        {user.isAdmin && adminData && (
                            <>
                                <Card.Text>
                                    Company Diamonds on-hand:<strong> {adminData.agentdiamond}</strong>💎
                                </Card.Text>
                                <Card.Text>
                                    Total Earnings from Game:<strong> {adminData.earnagentdiamond}</strong>💎
                                </Card.Text>
                                <Card.Text>Earned Diamonds in Peso:<strong>₱ {(adminData.earnagentdiamond/2)}</strong></Card.Text>
                            </>
                        )}
                        <br/>
                        <Card.Text>Total Earnings from Ads:<strong>₱ {totalActiveAmount.toLocaleString()}</strong></Card.Text>
						<br />
						<Link className="btn btn-success" to={`/admintransactionhistory`}>All Transaction History</Link>
						<br />
                        <br />
                        <Link className="btn btn-danger" to={`/liquidationlist`}>Liquidation</Link>
                    </Card.Body>
                </Card>
                <br />
				<div className="search-bar">
                    <input className='mr-sm-2'
                        type="text"
                        style={{ width: '460px' }}
                        placeholder="Search by username, first name, last name, email, mobile no., etc..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
					&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button className='btn btn-success' onClick={handleLoaderSearch}>Loading Station</Button>
                    <p>{searchMessage}</p>
                </div>
				<br />
                <br />
                <h4 id="agents" className="profile-list p-1 text-danger">List of Loading Stations</h4>
                <div>
                    {filteredLoadingStations.map((agent) => (
                        <AdminCard key={agent._id} agent={agent} />
                    ))}
                </div>
                <br />
            </>
    );
}
