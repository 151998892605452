import React, { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import LiquidationTransactionCard from '../components/LiquidationTransactionCard';

export default function LiquidationDashboard() {
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [pendingRequests, setPendingRequests] = useState([]);
    const [approvedRequests, setApprovedRequests] = useState([]);
    const [deniedRequests, setDeniedRequests] = useState([]);

    useEffect(() => {
        fetchRequests();
    }, []);

    useEffect(() => {
        filterResults();
    }, [searchTerm]);

    const createCashIn = async () => {
        const { value: diaAmount } = await Swal.fire({
            title: 'Cash-In Request',
            input: 'number',
            inputLabel: 'Enter the Diamond Amount 💎',
            inputPlaceholder: 'e.g., 100',
            showCancelButton: true,
            confirmButtonText: 'Submit',
        });

        if (diaAmount) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ diaAmount }),
                });

                const result = await response.json();

                if (response.ok) {
                    Swal.fire('Success', 'Cash-In request submitted successfully!', 'success');
                    fetchRequests();
                } else {
                    Swal.fire('Error', result.message || 'Failed to submit Cash-In request', 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'An error occurred while processing the request', 'error');
            }
        }
    };

    const createCashOut = async () => {
        const { value: pesoAmount } = await Swal.fire({
            title: 'Cash-Out Request',
            input: 'number',
            inputLabel: 'Enter the Peso Amount ₱',
            inputPlaceholder: 'e.g., 1000',
            showCancelButton: true,
            confirmButtonText: 'Submit',
        });

        if (pesoAmount) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ pesoAmount }),
                });

                const result = await response.json();

                if (response.ok) {
                    Swal.fire('Success', 'Cash-Out request submitted successfully!', 'success');
                    fetchRequests();
                } else {
                    Swal.fire('Error', result.message || 'Failed to submit Cash-Out request', 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'An error occurred while processing the request', 'error');
            }
        }
    };

    const fetchRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/getAllRequests`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setRequests(data);
            filterApprovedAndDeniedRequests(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    const filterApprovedAndDeniedRequests = (requests) => {
        const pending = requests.filter(
            (request) =>
                ['admin1Approved', 'admin2Approved', 'admin3Approved'].some((key) => request[key] === 'pending') &&
                ['admin1Approved', 'admin2Approved', 'admin3Approved'].every((key) => request[key] !== 'denied')
        );

        const approved = requests.filter((request) => request.isApproved);

        const denied = requests.filter(
            (request) =>
                ['admin1Approved', 'admin2Approved', 'admin3Approved'].some((key) => request[key] === 'denied') &&
                !request.isApproved
        );
        setPendingRequests(pending);
        setApprovedRequests(approved);
        setDeniedRequests(denied);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        let requestsResults = requests;

        if (searchTerm.trim() !== '') {
            requestsResults = requests.filter((request) =>
                Object.values(request).some(
                    (value) => typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );
            setSearchMessage(requestsResults.length === 0 ? 'Keyword not found' : '');
        } else {
            setSearchMessage('');
        }

        setPendingRequests(
            requestsResults.filter((request) =>
                ['admin1Approved', 'admin2Approved', 'admin3Approved'].some((key) => request[key] === 'pending')
            )
        );
        setApprovedRequests(requestsResults.filter((request) => request.isApproved));
        setDeniedRequests(
            requestsResults.filter((request) =>
                ['admin1Approved', 'admin2Approved', 'admin3Approved'].some((key) => request[key] === 'denied')
            )
        );
    };

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <h5 className="profile-name p-1 text-primary">Liquidations</h5>
            <br />
            <a href="#list" className="text-black mb-3">
                Go to List
            </a>
            <Form.Control
                type="text"
                placeholder="Search request..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                className="mb-3"
            />
            {searchMessage && <p className="text-danger">{searchMessage}</p>}
            <Card className="mt-3">
                <Card.Body>
                    <Card.Text>All Requests: {requests.length}</Card.Text>
                    <Card.Text>All Pendings: {pendingRequests.length}</Card.Text>
                    <Card.Text>All Approved: {approvedRequests.length}</Card.Text>
                    <Card.Text>All Denied: {deniedRequests.length}</Card.Text>
                </Card.Body>
            </Card>
            <br />
            <Link className="btn btn-success" onClick={createCashIn}>
                Cash-In
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-primary" onClick={createCashOut}>
                Cash-Out
            </Link>
            <br />
            <br />
            <h5 id="list" className="profile-list p-1 text-primary">Requests</h5>
            <h5 className="profile-list p-1 text-danger">Pending Requests</h5>
            <div>
                {pendingRequests.length > 0 ? (
                    pendingRequests.map((request) => (
                        <LiquidationTransactionCard key={request._id} request={request} />
                    ))
                ) : (
                    <p>No pending requests found.</p>
                )}
            </div>
            <br />
            <h5 className="profile-list p-1 text-danger">Approved Requests</h5>
            <div>
                {approvedRequests.length > 0 ? (
                    approvedRequests.map((request) => (
                        <LiquidationTransactionCard key={request._id} request={request} />
                    ))
                ) : (
                    <p>No approved requests.</p>
                )}
            </div>
            <br />
            <h5 className="profile-list p-1 text-danger">Denied Requests</h5>
            <div>
                {deniedRequests.length > 0 ? (
                    deniedRequests.map((request) => (
                        <LiquidationTransactionCard key={request._id} request={request} />
                    ))
                ) : (
                    <p>No denied requests.</p>
                )}
            </div>
        </>
    );
}