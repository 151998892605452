import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

import Swal from 'sweetalert2';


export default function BuyAgentTransactionView() {

    const {user} = useContext(UserContext)

    const navigate = useNavigate()

    const {profileId, transactionId} = useParams()

    const [userId, setUserId] = useState('')

    const setDiaTransfer = (profileId, transactionId) => {
		fetch(`${process.env.REACT_APP_API_URL}/transaction/buydiamondagent/${transactionId}/${profileId}`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "Diamond successfully transferred!"
				})
                
                navigate('/agent');
   
			} else {
				console.log(result)

				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function setTransfer(event){
        event.preventDefault()
        setDiaTransfer(profileId, transactionId);
    }

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/profile/${user.userId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.username)
			console.log(result.email)
			setUserId(result.userId)
		})
	}, [userId])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Transfer Diamond to user?</h2>
                    <Form onSubmit={event => setTransfer(event)}>
                        <Form.Group controlId="setTransfer">
                        </Form.Group>
                        <br></br>
                        <Button variant="success" type="submit" id="submitBtn">
                                Transfer
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Form>
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
}