import React, { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import Loading from '../components/Loading';
import UserCard from '../components/UserCard';

export default function ManageUserDashboard() {
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [ongoingProfiles, setOngoingProfiles] = useState([]);
    const [inactiveProfiles, setInactiveProfiles] = useState([]);

    useEffect(() => {
        fetchProfiles();
    }, []);

	// Automatic search on Searchbar
	useEffect(() => {
        filterResults();
    }, [searchTerm]);

    const fetchProfiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllUsers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setProfiles(data.users || []);
            filterOngoingAndInactiveProfiles(data.users || []);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Filter ongoing and inactive ads
    const filterOngoingAndInactiveProfiles = (profiles) => {
        const ongoing = profiles.filter((profile) => profile.isActive);
        const inactive = profiles.filter((profile) => !profile.isActive);

        setOngoingProfiles(ongoing);
        setInactiveProfiles(inactive);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        let profilesResults = profiles;
        if (searchTerm.trim() !== '') {
            profilesResults = profiles.filter((profile) =>
                Object.values(profile).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );
            if (profilesResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }
        setOngoingProfiles(profilesResults.filter(profile => profile.isActive));
        setInactiveProfiles(profilesResults.filter(profile => !profile.isActive));
    };

    return (
        isLoading ? <Loading /> :
            <>
                <h5 className="profile-name p-1 text-primary">All Users</h5>
                <br />
                <a href="#activelist" className='text-black mb-3'>Go to Active Users</a>
                <br />
                <a href="#deactivatedlist" className='text-black mb-3'>Go to Deactivted Users</a>
                <Form.Control 
                    type="text" 
                    placeholder="Search user..." 
                    value={searchTerm} 
                    onChange={handleSearchInputChange} 
                    className="mb-3"
                />
                {searchMessage && <p className="text-danger">{searchMessage}</p>}
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Text>All Users: {profiles.length}</Card.Text>
                        <Card.Text>All Active Users: {ongoingProfiles.length}</Card.Text>
                        <Card.Text>All Deactived Users: {inactiveProfiles.length}</Card.Text>
                    </Card.Body>
                </Card>
                <br />
				<br />
                <h5 className="profile-list p-1 text-primary">Users</h5>
                <h5 id="activelist" className="profile-list p-1 text-danger">Active Users</h5>
                <div>
                    {ongoingProfiles.length > 0 ? (
                        ongoingProfiles.map((profile) => <UserCard key={profile._id} profile={profile} />)
                    ) : (
                        <p>No active users found.</p>
                    )}
                </div>
                <br/>
                <h5 id="deactivatedlist" className="profile-list p-1 text-danger">Deactivated Users</h5>
                <div>
                    {inactiveProfiles.length > 0 ? (
                        inactiveProfiles.map((profile) => <UserCard key={profile._id} profile={profile} />)
                    ) : (
                        <p>No deactivated users.</p>
                    )}
                </div>
            </>
    );
}
