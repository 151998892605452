import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const LoaderDashboard = () => {
    const [requests, setRequests] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/getPendingRequests`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setRequests(data);
            // onPendingRequest(data.length);
            if (data.length > 0) {
                showRequestAlert(data[0])
            };
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };

    const acceptLoaderRequest = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/acceptRequest`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    const denyLoaderRequest = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/loaderRequest/denyRequest`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
            });
        return response.json();
    };

    const showRequestAlert = (request) => {
        Swal.fire({
            title: 'Player Loader Change Request',
            text: `Player ${request.userName} wants to join you.`,
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Accept',
            denyButtonText: 'Deny',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await acceptLoaderRequest({ requestId: request._id });
                Swal.fire('Accepted', 'Request accepted successfully.', 'success');
                navigate('/');
            }
            if (result.isDenied) {
                await denyLoaderRequest({ requestId: request._id });
                Swal.fire('Denied', 'Player or user denied.', 'error');
                navigate('/');
            }
        });
    };
};

export default LoaderDashboard;