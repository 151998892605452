import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';

export default function ActivateUser() {
    const {user} = useContext(UserContext)
    const navigate = useNavigate()
    const {profileId} = useParams()
    const [userId, setUserId] = useState('')

    const activateUser = (profileId) => {
		fetch(`${process.env.REACT_APP_API_URL}/users/activateUser/${profileId}`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "User Activated!"
				})
                navigate('/suspenduser');
			} else {
				console.log(result)
				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function suspendUser(event){
        event.preventDefault()
        activateUser(profileId);
    }

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/profile/${user.userId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.username)
			console.log(result.email)
			setUserId(result.userId)
		})
	}, [userId])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Activate User?</h2>
                    <Form onSubmit={event => suspendUser(event)}>
                        <Form.Group controlId="suspendUser">
                        </Form.Group>
                        <br></br>
                        <Button variant="danger" type="submit" id="submitBtn">
                            Confirm
                        </Button>
                    </Form>
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
};