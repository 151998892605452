import React, { useContext } from 'react';
import Banner from '../components/Banner';
import Highlights from '../components/Highlights';
import HomeDiamonds from './HomeDiamonds';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/MainDashboard.css';
import Advertisement from './Advertisement';
import UserContext from '../UserContext';
import LoaderDashboard from '../components/LoaderDashboard';
import LiquidationRequest from '../components/LiquidationRequest';

export default function Home() {
    const {user} = useContext(UserContext);

    return (
        <div>
            {(user.isAgent) ? (
                <>
                    <LoaderDashboard/>
                    <Banner/>
                    <main className="main">
                        <div className="left-section">
                            <HomeDiamonds/>
                            <Highlights/>
                        </div>
                        <div className="right-section">
                        <h6>Ads</h6>
                                <div className="ads">
                                    <Advertisement/>
                                </div>
                            </div>
                    </main>
                </>
            ) : (
                <>
                    <LiquidationRequest/>
                    <main className="main">
                        <div className="left-section">
                            <HomeDiamonds/>
                            <Highlights/>
                        </div>
                        <div className="right-section">
                        <h6>Ads</h6>
                            <div className="ads">
                                <Advertisement/>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </div>
    )
}