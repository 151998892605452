import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const LiquidationRequest = () => {
    const [requests, setRequests] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setRequests(data);
            // onPendingRequest(data.length);
            if (data.length > 0) {
                showRequestAlert(data[0])
            };
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    const acceptRequest = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    const denyRequest = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    const showRequestAlert = (request) => {
        Swal.fire({
            title: 'Liquidation Request',
            text: `A liquidation request with amount ${((request.diaAmount)? `${request.diaAmount} 💎` : "" )} ${((request.pesoAmount)? `₱ ${request.pesoAmount}` : "" )} from ${request.requester} needs your approval.`,
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Accept',
            denyButtonText: 'Deny',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await acceptRequest({ requestId: request._id, status: 'accepted' });
                Swal.fire('Accepted', 'Request accepted successfully.', 'success');
                navigate('/');
            }
            if (result.isDenied) {
                await denyRequest({ requestId: request._id, status: 'denied' });
                Swal.fire('Denied', 'Request declined.', 'error');
                navigate('/');
            }
            if (result.isDismissed) {
                navigate('/liquidationlist');
            }
        });
    };
};

export default LiquidationRequest;