import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import UserContext from '../UserContext';

export default function MerchantProduct() {
    const { user } = useContext(UserContext);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [ongoingProducts, setOngoingProducts] = useState([]);
    const [inactiveProducts, setInactiveProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);

	// Automatic search on Searchbar
	useEffect(() => {
        filterResults();
    }, [searchTerm]);

    const fetchProducts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/shopproduct/merchantproducts`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setProducts(data.products || []);
            filterOngoingAndInactiveProducts(data.products || []);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    // Filter ongoing and inactive ads
    const filterOngoingAndInactiveProducts = (products) => {
        const ongoing = products.filter((product) => product.isActive);
        const inactive = products.filter((product) => !product.isActive);
        setOngoingProducts(ongoing);
        setInactiveProducts(inactive);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();
        let productsResults = products;
        if (searchTerm.trim() !== '') {
            productsResults = products.filter((product) =>
                Object.values(product).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );
            setSearchMessage(productsResults.length === 0 ? 'Keyword not found' : '');
        } else {
            setSearchMessage('');
        }
        setOngoingProducts(productsResults.filter(product => product.isActive));
        setInactiveProducts(productsResults.filter(product => !product.isActive));
    };

    return (
        isLoading ? <Loading /> :
            <>
                <h5 className="profile-name p-1 text-primary">Merchant Products</h5>
                <br />
                <a href="#list" className='text-black mb-3'>Go to List</a>
                <Form.Control 
                    type="text" 
                    placeholder="Search products..." 
                    value={searchTerm} 
                    onChange={handleSearchInputChange} 
                    className="mb-3"
                />
                {searchMessage && <p className="text-danger">{searchMessage}</p>}
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Text>All Products: {products.length}</Card.Text>
                        <Card.Text>All Active Products: {ongoingProducts.length}</Card.Text>
                        {(user.isMerchant) ? (
                            <Link className="btn btn-danger" to={`/shoppingmall/create`}>Add Product</Link>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
                <br />
				<br />
                <h5 id="list" className="profile-list p-1 text-primary">Products List</h5>
                <h5 className="profile-list p-1 text-danger">Ongoing/Active Products </h5>
                <div className="d-flex flex-wrap">
                    {ongoingProducts.length > 0 ? (
                        ongoingProducts.map((product) => (
                            <Card key={product._id} className="m-2" style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={product.imageUrl[0]} />
                                <Card.Body>
                                    <Card.Title>{product.name}</Card.Title>
                                    <Card.Text>Price: {product.sellingprice.toLocaleString()} 💎</Card.Text>
                                    <Card.Text>{product.description}</Card.Text>
                                    <Button variant="primary" as={Link} to={`/shoppingmall/${product._id}`}>
                                        View Product
                                    </Button>
                                </Card.Body>
                            </Card>
                        ))
                    ) : (
                        <p>No ongoing products found.</p>
                    )}
                </div>
                <h5 className="text-primary mt-4">Inactive Products</h5>
                <div className="d-flex flex-wrap">
                    {inactiveProducts.length > 0 ? (
                        inactiveProducts.map((product) => (
                            <Card key={product._id} className="m-2" style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={product.imageUrl[0]} />
                                <Card.Body>
                                    <Card.Title>{product.name}</Card.Title>
                                    <Card.Text>Price: {product.sellingprice.toLocaleString()} 💎</Card.Text>
                                    <Card.Text>{product.description}</Card.Text>
                                    <Button variant="secondary" as={Link} to={`/shoppingmall/${product._id}`}>
                                        View Product
                                    </Button>
                                </Card.Body>
                            </Card>
                        ))
                    ) : (
                        <p>No inactive products found.</p>
                    )}
                </div>
            </>
    );
}
