import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function LiquidationTransactionCard({ request }) {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Allowed file types
  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
  
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type));

    if (validFiles.length < files.length) {
      Swal.fire('Error', 'Only .png, .jpg, .jpeg, and .pdf files are allowed.', 'error');
    }

    setSelectedFiles((prev) => [...prev, ...validFiles]);
  };

  const handleApprove = async () => {
    // const formData = new FormData();
    // formData.append('requestId', request._id);
    // formData.append('status', 'accepted');
    // selectedFiles.forEach((file) => {
    //   formData.append('files', file);
    // });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        // body: formData,
        body: JSON.stringify({
          requestId: request._id,
          status: 'accepted',
        }),
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire('Success', 'Request approved successfully.', 'success');
        navigate('/liquidationlist');
      } else {
        Swal.fire('Approve', result.message || 'Failed to approve request.', 'success');
        navigate('/liquidationlist');
      }
    } catch (error) {
      console.error('Error approving request:', error);
      Swal.fire('Error', 'An unexpected error occurred.', 'error');
    }
  };

  // Function to handle denial
  const handleDeny = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/liquidation/request`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          requestId: request._id,
          status: 'denied',
        }),
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire('Success', 'Request denied successfully.', 'success');
        navigate('/liquidationlist');
      } else {
        Swal.fire('Denied', result.message || 'Failed to deny request.', 'success');
      }
    } catch (error) {
      console.error('Error denying request:', error);
      Swal.fire('Error', 'An unexpected error occurred.', 'error');
    }
  };

  return (
    <div className="pb-5">
      <Col xs={12} md={6}>
        <Card>
          <Card.Body>
            <Card.Title>{request.diaAmount ? 'Cash-In' : 'Cash-Out'}</Card.Title>
            <Card.Text>
              <strong>Requested By:</strong> {request.requester}
            </Card.Text>
            <Card.Text>
              <strong>Admin 1:</strong> {request.admin1Approved}
            </Card.Text>
            <Card.Text>
              <strong>Admin 2:</strong> {request.admin2Approved}
            </Card.Text>
            <Card.Text>
              <strong>Admin 3:</strong> {request.admin3Approved}
            </Card.Text>
            <Card.Text>
              {request.diaAmount ? (
                <>
                  <strong>Diamond Amount:</strong> {request.diaAmount}💎
                </>
              ) : (
                <>
                  <strong>Peso Amount:</strong>₱ {request.pesoAmount}
                </>
              )}
            </Card.Text>
            <Card.Text>
              <strong>Approved:</strong> {request.isApproved ? 'Yes' : 'No'}
            </Card.Text>
            <Card.Text>
              <strong>Date:</strong> {request.timeStamp}
            </Card.Text>

            {!request.isApproved &&
              ["admin1Approved", "admin2Approved", "admin3Approved"].some(field => request[field] !== "accepted") &&
              ["admin1Approved", "admin2Approved", "admin3Approved"].every(field => request[field] !== "denied") &&
              (<>
                  <input
                    type="file"
                    multiple
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={handleFileChange}
                    className="form-control mb-3"
                  />
                  <button className="btn btn-success" onClick={handleApprove}>
                    Approve
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button className="btn btn-danger" onClick={handleDeny}>
                    Deny
                  </button>
                </>
              )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};